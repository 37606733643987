import URLS from "common/utils/apiUrls";

import ApiService from "./api.service";

const UserService = {
    getCurrentUserSession() {
        const axiosOptions = {};
        return ApiService.get(URLS.currentUserSession, axiosOptions);
    },

    // getAllUserSessions() {
    //   let axiosOptions = Object.assign(
    //     {}
    //     // { params: params },
    //     // this.getCommonHeaderOptions()
    //   );
    //   return ApiService.get(URLS.allUserSessions, axiosOptions);
    // }

    createOrganization(data) {
        // const storeState = store.getState();
        const axiosOptions = {
            data,
        };
        return ApiService.post(URLS.createOrganization, axiosOptions);
    },

    getOrganizationDetails() {
        const axiosOptions = {};
        return ApiService.get(URLS.getOrganizationDetails, axiosOptions);
    },

    getOrganizationsForUser() {
        const axiosOptions = {};
        return ApiService.get(URLS.getOrganizationsForUser, axiosOptions);
    },

    getOrganizationDetailsById(orgId) {
        const axiosOptions = {};
        return ApiService.get(
            URLS.getOrganizationDetailsById.replace(":orgId", orgId),
            axiosOptions,
        );
    },
    getUsersByIds(ids) {
        return ApiService.post(URLS.getUsersByIds, { data: { ids } });
    },
};

export default UserService;
