const environment = window._conf || {
    VALUE: "development",
    PORT: 9090,
    newrelic_app: "",
    newrelic_license_key: "",
    API_DOMAIN: "api.aipim.sit.fyndx1.de",
    CATALOG_CLOUD_BASE_URL: "https://local.aipim.sit.fyndx1.de:9090",
    BALROG_MAIN_URL: "https://api.aipim.sit.fyndx1.de/service/panel/users",
    NENYA_MAIN_URL: "https://api.aipim.sit.fyndx1.de/service/panel/templates",
    RINGIL_MAIN_URL:
        "https://api.aipim.sit.fyndx1.de/service/panel/integrations",
    MITHRANDIR_MAIN_URL:
        "https://api.aipim.sit.fyndx1.de/service/panel/organization",
    VALAR_MAIN_URL: "https://api.aipim.sit.fyndx1.de/service/panel/catalog",
    VILYA_PLTM_URL: "https://api.aipim.sit.fyndx1.de/service/panel/assets",
    PALANTIR_PLTM_URL: "https://api.aipim.sit.fyndx1.de/service/panel/webhook",
    VALAR_PUBLIC_URL: "https://api.aipim.sit.fyndx1.de/public/catalog-webhook",
    BOLTIC_SOURCE_PARAM: "catalog-cloud",
    FYNDCONSOLE_LOGIN_URL: "https://central.sit.fyndx1.de/auth/sign-in",
    FYNDCONSOLE_HOME_URL: "https://central.sit.fyndx1.de",
};

export default environment;
