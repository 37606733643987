const { isBrowser } = require("browser-or-node");

const { sign } = require("./signature");

/**
 * Creates a new URL by combining the specified URLs
 *
 * @param {string} baseURL The base URL
 * @param {string} relativeURL The relative URL
 *
 * @returns {string} The combined URL
 */
function combineURLs(baseURL, relativeURL) {
    return relativeURL
        ? baseURL.replace(/\/+$/, "") + "/" + relativeURL.replace(/^\/+/, "")
        : baseURL;
}

/**
 * Determines whether the specified URL is absolute
 *
 * @param {string} url The URL to test
 *
 * @returns {boolean} True if the specified URL is absolute, otherwise false
 */
function isAbsoluteURL(url) {
    // A URL is considered absolute if it begins with "<scheme>://" or "//" (protocol-relative URL).
    // RFC 3986 defines scheme name as a sequence of characters beginning with a letter and followed
    // by any combination of letters, digits, plus, period, or hyphen.
    return /^([a-z][a-z\d+\-.]*:)?\/\//i.test(url);
}

function transformRequestOptions(params) {
    let options = "";

    for (const key in params) {
        if (
            typeof params[key] !== "object" &&
            typeof params[key] !== "undefined"
        ) {
            const encodeVal = encodeURIComponent(params[key]);
            options += `${key}=${encodeVal}&`;
        } else if (Array.isArray(params[key])) {
            params[key].forEach(el => {
                const encodeVal = encodeURIComponent(params[key]);
                options += `${key}=${encodeVal}&`;
            });
        } else if (typeof params[key] === "object" && params[key]) {
            options += transformRequestOptions(params[key]);
        }
    }
    return options ? options.slice(0, -1) : options;
}

function getTransformer(config) {
    const { transformRequest } = config;

    if (transformRequest) {
        if (typeof transformRequest === "function") {
            return transformRequest;
        } else if (transformRequest.length) {
            return transformRequest[0];
        }
    }

    throw new Error(
        "Could not get default transformRequest function from Axios defaults",
    );
}

export function processQueryParams({ params, search }) {
    let queryParam = "";
    if (params && Object.keys(params).length) {
        if (search && search.trim() !== "") {
            queryParam = `&${transformRequestOptions(params)}`;
        } else {
            queryParam = `?${transformRequestOptions(params)}`;
        }
    }
    return queryParam;
}

const DefaultAxiosHeaders = new Set([
    "common",
    "get",
    "delete",
    "head",
    "post",
    "put",
    "patch",
]);

function interceptorFn(options) {
    return config => {
        if (!config.url) {
            throw new Error(
                "No URL present in request config, unable to sign request",
            );
        }

        let url = config.url;
        if (config.baseURL && !isAbsoluteURL(config.url)) {
            url = combineURLs(config.baseURL, config.url);
        }
        if (url.startsWith("/api") && isBrowser) {
            url = `https://${window.location.host}${url}`;
        }
        const { host, pathname, search } = new URL(url);
        if (
            pathname.startsWith("/service/panel") ||
            pathname.startsWith("/api/service/panel")
        ) {
            const { data, headers, method, params } = config;
            const queryParam = processQueryParams({ params, search });
            const transformRequest = getTransformer(config);
            const transformedData = transformRequest(data, headers);

            // Remove all the default Axios headers
            const headersToSign = Object.fromEntries(
                Object.entries(headers).filter(
                    ([key, _]) => !DefaultAxiosHeaders.has(key),
                ),
            );
            const signingOptions = {
                method: method && method.toUpperCase(),
                host: host,
                path: pathname + search + queryParam,
                body: transformedData,
                headers: headersToSign,
            };

            if (
                signingOptions["body"] &&
                signingOptions["body"] instanceof FormData
            ) {
                delete signingOptions["body"];
            }
            sign(signingOptions);
            config.headers["x-ccl-param"] =
                signingOptions.headers["x-ccl-param"];
            config.headers["x-ccl-signature"] =
                signingOptions.headers["x-ccl-signature"];
        }
        return config;
    };
}

export const addSignatureFn = interceptorFn;
