import React from "react";

import { ReactComponent as PIMLogoDarkBg } from "./FyndPimLogoDarkBg.svg";
import { ReactComponent as PIMLogoWhiteBg } from "./FyndPimLogoWhiteBg.svg";

const Logo = ({ black = false }) => {
    return black ? <PIMLogoWhiteBg /> : <PIMLogoDarkBg />;
};

export default Logo;
