import { useSelector } from "react-redux";
import CryptoJS from "crypto-js";
import { useCallback, useEffect, useRef } from "react";

import { URL_REGEX } from "../constants/global.constants";

export function debounce(func, wait = 300) {
    let timeout;

    return function executedFunction(...args) {
        const later = () => {
            clearTimeout(timeout);
            func(...args);
        };

        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
    };
}

const MONTHS = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

export function convertEpoch(epoch) {
    epoch *= 1000;
    const date = new Date(epoch);
    const year = date.getFullYear();
    if (year === 1970) {
        return "NA";
    }
    return date.getDate() + ", " + MONTHS[date.getMonth()] + ", " + year;
}

export function toUpperCase(s) {
    return s.toUpperCase()[0] + s.slice(1);
}

export function base64Encode(text) {
    return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(text));
}
export function base64Decode(text) {
    return CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(text));
}

export function handleScroll(container, dir = "left") {
    if (dir.toLowerCase() === "left") {
        container.scrollLeft += container.clientWidth;
    } else {
        container.scrollLeft -= container.clientWidth;
    }
}

export const selKeys = (keys, obj) =>
    obj &&
    Object.entries(obj)
        .filter(([key]) => keys.includes(key))
        .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

export const convertStringToDropdownObject = data => {
    const objectList = [];
    for (const value of data) {
        objectList.push({ text: value, value });
    }
    return objectList;
};

export const checkEmptyValue = (value, tag) => {
    let error = false;
    let errorText = "";
    if (value.trim() === "") {
        error = true;
        errorText = `${tag} is required`;
    }

    return { error, errorText };
};

export const checkValidNumber = (value, tag) => {
    let error = false;
    let errorText = "";
    if (isNaN(value)) {
        error = true;
        errorText = `${tag} should be a valid number`;
    }

    return { error, errorText };
};

export const checkValidInteger = (value, tag) => {
    let error = false;
    let errorText = "";
    if (isNaN(value) || !Number.isInteger(Number(value))) {
        error = true;
        errorText = `${tag} should be an integer`;
    }
    return { error, errorText };
};

export const checkValidPositiveNumber = (value, tag) => {
    let error = false;
    let errorText = "";
    const { error: numberError } = checkValidNumber(value, tag);
    if (numberError || parseFloat(value) <= 0) {
        error = true;
        errorText = `${tag} should be greater than 0`;
    }

    return { error, errorText };
};

export const checkValidPositiveInteger = (value, tag) => {
    let error = false;
    let errorText = "";
    const { error: integerError } = checkValidInteger(value, tag);
    if (integerError || parseInt(value) <= 0) {
        error = true;
        errorText = `${tag} should be an integer greater than 0`;
    }
    return { error, errorText };
};

export const checkValidMinMax = (min, max, caller) => {
    const convMin = parseFloat(min);
    const convMax = parseFloat(max);
    let error = false;
    let errorText = "";
    if (!Number.isNaN(convMin) && !Number.isNaN(convMax) && convMin > convMax) {
        error = true;
        errorText =
            caller === "Min"
                ? `Min should be less than Max value`
                : "Max should be greater than Min value";
    }
    return { error, errorText };
};

export const checkValidMinMaxInteger = (min, max, caller) => {
    let error = false;
    let errorText = "";
    const { error: intError } =
        caller === "Min"
            ? checkValidInteger(min, "Min")
            : checkValidInteger(max, "Max");
    const { error: minMaxError } = checkValidMinMax(min, max, caller);
    if (intError || minMaxError) {
        error = true;
        errorText =
            caller === "Min"
                ? `Min should be an integer less than Max value`
                : "Max should be an integer greater than Min value";
    }

    return { error, errorText };
};

export const useUserDetails = () => {
    const user = useSelector(state => state?.authenticationDetails?.user);
    const members = useSelector(state => state?.organizationDetails?.member);

    const tempUser = user ? { ...user, type: null } : {};
    if (tempUser) {
        const member = members?.find(item => item?.userId === user?._id);
        if (member) {
            tempUser.type = member?.type;
        } else tempUser.type = null;
    }

    return tempUser;
};

export const passwordErrorText = () => {
    return "Password must contain a minimum of 8 characters, including at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character.";
};

export const passwordErrorText2 = () => {
    return "It must contain a minimum of 8 characters and include at least 1 small case letter, 1 capital letter, 1 number and 1 special character.";
};

export async function clearCookieAndStorage() {
    const theCookies = document.cookie.split(";");
    for (let i = 1; i <= theCookies.length; i++) {
        const acookie = theCookies[i - 1];
        const cookieArr = acookie.split("=");
        document.cookie =
            cookieArr[0] + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    }

    // Get cache storage and clear cache storage
    window.caches.keys().then(function (names) {
        for (const name of names) window.caches.delete(name);
    });

    // Get indexed db and delete indexed db
    const dbs = await window.indexedDB.databases();
    dbs.forEach(db => {
        window.indexedDB.deleteDatabase(db.name);
    });

    // clear localStorage
    window.localStorage.clear();

    // clear sessionStorage
    window.sessionStorage.clear();
}

export const applyFormatting = (value, formatting) => {
    if (formatting === "Upper" || formatting === "Uppercase") {
        return value?.toLocaleUpperCase?.() ?? value;
    } else if (formatting === "Lowercase" || formatting === "Lower") {
        return value?.toLocaleLowerCase?.() ?? value;
    } else {
        return value;
    }
};

export function checkIfEmpty(value) {
    if (typeof value === "string") {
        return value.trim() === "";
    }

    if (
        value === null ||
        (typeof value === "number" && Number.isNaN(value)) ||
        typeof value === "undefined"
    ) {
        return true;
    }

    if (typeof value === "object") {
        if (Array.isArray(value)) {
            return value.length === 0;
        }

        return Object.keys(value).length === 0;
    }

    return false;
}

export function minifyCode(code) {
    if (typeof code !== "string") return "";
    const minified = code
        // Remove newlines and extra spaces
        .replace(/\s+/g, " ")
        // Remove single-line comments
        .replace(/(\/\/.*)/g, "");

    return minified.trim();
}

export function prettifyBytes(bytes) {
    if (!+bytes) return "0 bytes";
    const units = ["bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    let i;
    for (i = 0; bytes >= 1000 && i < units.length - 1; i++) {
        bytes /= 1000;
    }
    return `${bytes.toFixed(2)} ${units[i]}`;
}

// Reference: https://blog.devgenius.io/how-to-better-poll-apis-in-react-312bddc604a4
export const useIntervalAsync = (fn, ms) => {
    const runningCount = useRef(0);
    const timeout = useRef();
    const mountedRef = useRef(false);

    const next = useCallback(
        handler => {
            if (mountedRef.current && runningCount.current === 0) {
                timeout.current = window.setTimeout(handler, ms);
            }
        },
        [ms],
    );

    const run = useCallback(
        async (...args) => {
            runningCount.current += 1;
            const result = await fn(...args);
            runningCount.current -= 1;

            next(run);

            return result;
        },
        [fn, next],
    );

    useEffect(() => {
        mountedRef.current = true;
        run();

        return () => {
            mountedRef.current = false;
            window.clearTimeout(timeout.current);
        };
    }, [run]);

    const flush = useCallback(
        (...args) => {
            window.clearTimeout(timeout.current);
            return run(...args);
        },
        [run],
    );

    return flush;
};

export function isValidDateString(dateString) {
    // Regular expression to match the accepted date formats
    const regex =
        /^(?<date>\d{4}[-/](0?[1-9]|1[0-2])[-/](0?[1-9]|[12]\d|3[01]))(?<time> ([01]?\d|2[0-3]):([0-5]?\d):([0-5]?\d))?$/;

    // Match the date string against the regex
    const match = dateString.match(regex);
    if (!match) {
        return false;
    }

    const { date, time } = match.groups;

    // Parse the date components
    const [year, month, day] = date.split(/[-/]/).map(Number);

    // Check if the date is valid using Date object
    const dateObj = new Date(year, month - 1, day);
    if (
        dateObj.getFullYear() !== year ||
        dateObj.getMonth() + 1 !== month ||
        dateObj.getDate() !== day
    ) {
        return false;
    }

    // If time is provided, validate the time
    if (time) {
        const [hours, minutes, seconds] = time.trim().split(":").map(Number);
        if (
            hours < 0 ||
            hours > 23 ||
            minutes < 0 ||
            minutes > 59 ||
            seconds < 0 ||
            seconds > 59
        ) {
            return false;
        }
    }

    return true;
}

export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export function isObjectLike(value) {
    return Object.prototype.toString.call(value) === "[object Object]";
}

export function getUrlSearchParams({ data = null, json = false }) {
    const urlSearchParams = new URLSearchParams(window.location.search);

    if (data && isObjectLike(data)) {
        for (const key in data) {
            const value = data[key];

            // Remove key if value is null, undefined, empty string, or empty array
            if (
                value === null ||
                value === undefined ||
                value === "" ||
                (Array.isArray(value) && value.length === 0)
            ) {
                urlSearchParams.delete(key);
            } else {
                // Update or add key-value pair to URLSearchParams
                urlSearchParams.set(key, value);
            }
        }
    }
    if (json) {
        return Object.fromEntries(urlSearchParams);
    }

    return urlSearchParams;
}

export function formatPriceToStandard(price, currency) {
    return price?.toLocaleString("en-IN", {
        maximumFractionDigits: 0,
        style: "currency",
        currency: "INR",
    });
}
export const currencyConverter = {
    INR: lowestDenomination => lowestDenomination / 100,
    USD: lowestDenomination => lowestDenomination / 100,
    // Add more currencies as needed
};

export const isValidUrl = url => {
    if (typeof url !== "string" || url === "") return false;

    return URL_REGEX.test(url);
};

export const handleNumberKeyDown = e => {
    const exceptThisSymbols = ["+", ".", "e", "E"];
    exceptThisSymbols.includes(e.key) && e.preventDefault();
};

export const handleDecimalKeyDown = e => {
    const exceptThisSymbols = ["+", "e", "E"];
    exceptThisSymbols.includes(e.key) && e.preventDefault();
};
