import UserService from "services/user.service";
import OrgService from "services/org.service";

import { OrgAction } from "../common/redux/actions/orgActions";
import store from "../common/redux/store";

const OrganizationGuard = async () => {
    const storeState = store.getState(); // Access the Redux store state
    const path = window.location.pathname;

    const pathArray = path.split("/");
    const isChooseOrgPath = path.includes("org/choose-org");
    const isHome = path.includes("organization");

    const requestedOrgId = pathArray[pathArray.indexOf("organization") + 1];
    const currentOrg = storeState["organizationDetails"]["organization"];
    const currentOrgId = currentOrg ? currentOrg._id : null;
    const organizations = storeState["organizationDetails"]["organizations"];
    const requestedOrgDetails =
        organizations?.find(org => org._id === requestedOrgId) ?? null;
    const userId = storeState["authenticationDetails"]?.["user"]?._id;

    // Case 1: Organization details exist in store
    if (currentOrg) {
        // Check if the requested orgId is different from the current one
        if (isHome && requestedOrgId && currentOrgId !== requestedOrgId) {
            if (requestedOrgDetails) {
                // Update the orgId in Redux store without redirection
                store.dispatch({
                    type: OrgAction.SetCurrentOrganization,
                    payload: { organization: requestedOrgDetails },
                });
            } else return "/org/choose-org";
        }
        if (isHome && !userId) {
            return "/"; // Redirect to home if userId is not available
        }

        if (isHome) {
            const teamResponse = await OrgService.getAllTeams(requestedOrgId);
            store.dispatch({
                type: OrgAction.GetOrganizationDetails,
                payload: { member: teamResponse.data.team },
            });
        }
        return ""; // No redirection needed
    }

    // Case 2: List of organizations exists in store
    if (organizations?.length) {
        // Check if the requested orgId exists in the organizations list
        if (isHome && !organizations.some(org => org._id === requestedOrgId)) {
            return "/org/choose-org"; // Redirect to choose organization
        }

        // Update the orgId in Redux store without redirection if currentOrgId is not set
        if (requestedOrgId && currentOrgId !== requestedOrgId) {
            if (requestedOrgDetails) {
                // Update the orgId in Redux store without redirection
                store.dispatch({
                    type: OrgAction.SetCurrentOrganization,
                    payload: { organization: requestedOrgDetails },
                });
            } else return "/org/choose-org";
        }

        return ""; // No redirection needed
    }

    // Case 3: No organization data, handle choose-org path
    if (isChooseOrgPath) {
        try {
            const res = await UserService.getOrganizationsForUser();
            store.dispatch({
                type: OrgAction.GetOrganizationsForUser,
                payload: { organizations: res.data },
            });
        } catch (err) {
            console.error(err);
        }
        return ""; // No redirection needed
    }

    // Case 4: Fetch organization details by orgId
    try {
        if (!requestedOrgId) return ""; // No organization ID, no redirection needed

        const [orgDetails, teamResponse] = await Promise.all([
            UserService.getOrganizationDetailsById(requestedOrgId),
            OrgService.getAllTeams(requestedOrgId),
        ]);

        store.dispatch({
            type: OrgAction.GetOrganizationDetails,
            payload: {
                isOrgRegistered: true,
                organization: orgDetails.data.detail,
                member: teamResponse.data.team,
            },
        });
    } catch (err) {
        console.error(err);
        return "/org/choose-org"; // Redirect to choose organization on error
    }

    return ""; // No redirection needed
};

export default OrganizationGuard;
