import URLS from "../../src/common/utils/apiUrls";

import ApiService from "./api.service";

const OrgService = {
    createOrganization(data) {
        // const storeState = store.getState();
        const axiosOptions = {
            data,
        };
        return ApiService.post(URLS.createOrganization, axiosOptions);
    },

    createApp(orgId, data) {
        const axiosOptions = { data };
        return ApiService.post(
            `${URLS.createApp}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },

    getAppsForOrganization(
        orgId,
        page = 1,
        pageSize = 10,
        name = "",
        otherFilters = "",
    ) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.getApps}`
                .replace(":orgId", orgId)
                .replace(":page", page)
                .replace(":pageSize", pageSize)
                .replace(":name", name) + otherFilters,
            axiosOptions,
        );
    },

    // getAppsForOrganization(orgId, queryString) {
    //     let axiosOptions = {};
    //     return ApiService.get(
    //         `${URLS.getApps}?${queryString}`.replace(":orgId", orgId),
    //         axiosOptions,
    //     );
    // },

    updateOrg(data, orgId) {
        const axiosOptions = {
            data,
        };
        return ApiService.patch(
            `${URLS.updateOrganizationDetailsById}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },

    updateApp(orgId, appId, data) {
        const axiosOptions = {
            data,
        };
        return ApiService.patch(
            `${URLS.updateApp}`
                .replace(":orgId", orgId)
                .replace(":appId", appId),
            axiosOptions,
        );
    },
    getAppDetails(orgId, appId) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.getAppDetails}`
                .replace(":orgId", orgId)
                .replace(":appId", appId),
            axiosOptions,
        );
    },
    deleteApp(orgId, appId) {
        const axiosOptions = {};
        return ApiService.delete(
            `${URLS.deleteApp}`
                .replace(":orgId", orgId)
                .replace(":appId", appId),
            axiosOptions,
        );
    },
    createTeamMember(orgId, data) {
        const axiosOptions = { data };
        return ApiService.post(
            `${URLS.createTeamMember}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },
    addUserInOrgAndUserGroup(orgId, data) {
        const axiosOptions = { data };
        return ApiService.post(
            `${URLS.addUserInOrgAndUserGroup}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },
    acceptInvite(inviteId) {
        return ApiService.post(
            URLS.acceptInvite.replace(":inviteId", inviteId),
        );
    },
    getInvitationList(status) {
        return ApiService.get(
            URLS.getInvitationList.replace(":status", status),
        );
    },
    editUserGroupMapping(orgId, data) {
        const axiosOptions = { data };
        return ApiService.patch(
            `${URLS.editUserGroupMapping}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },
    getTeam(orgId, userId, page, pageSize) {
        const axiosOptions = {};
        if (!userId) {
            userId = "";
        }
        if (!page) {
            page = "";
        }
        if (!pageSize) {
            pageSize = "";
        }
        return ApiService.get(
            `${URLS.getTeam}`
                .replace(":orgId", orgId)
                .replace(":userId", userId)
                .replace(":page", page)
                .replace(":pageSize", pageSize),
            axiosOptions,
        );
    },
    getAllTeams(orgId) {
        return ApiService.get(`${URLS.getAllTeams}`.replace(":orgId", orgId));
    },
    getAllUserDetails(orgId) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.getAllTeams}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },
    updateTeamMember(orgId, memberId, data) {
        const axiosOptions = { data };
        return ApiService.patch(
            `${URLS.updateTeamMember}`
                .replace(":orgId", orgId)
                .replace(":memberId", memberId),
            axiosOptions,
        );
    },
    searchUsers(orgId, email) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.searchUsers}`
                .replace(":orgId", orgId)
                .replace(":email", email),
            axiosOptions,
        );
    },
    deleteTeamMember(orgId, memberId) {
        const axiosOptions = {};
        return ApiService.delete(
            `${URLS.deleteTeamMember}`
                .replace(":orgId", orgId)
                .replace(":memberId", memberId),
            axiosOptions,
        );
    },
    getInvitedMemberDetails({ query }) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.getInviteDetails}`.replace(":inviteId", query?._id || ""),
            axiosOptions,
        );
    },

    createUserGroup(orgId, data) {
        const axiosOptions = { data };
        return ApiService.post(
            `${URLS.createUserGroup}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },

    updateUserGroup(orgId, userGroupId, data) {
        const axiosOptions = { data };
        return ApiService.patch(
            `${URLS.updateUserGroup}`
                .replace(":orgId", orgId)
                .replace(":userGroupId", userGroupId),
            axiosOptions,
        );
    },
    deleteUserGroup(orgId, userGroupId) {
        const axiosOptions = {};
        return ApiService.delete(
            `${URLS.deleteUserGroup}`
                .replace(":orgId", orgId)
                .replace(":userGroupId", userGroupId),
            axiosOptions,
        );
    },

    getInvitedMembers(orgId) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.getInviteListByOrgId}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },

    getUserGroups(orgId, page, pageSize) {
        const axiosOptions = {};
        if (!page) {
            page = "";
        }
        if (!pageSize) {
            pageSize = "";
        }
        return ApiService.get(
            `${URLS.getUserGroups}`
                .replace(":orgId", orgId)
                .replace(":page", page)
                .replace(":pageSize", pageSize),
            axiosOptions,
        );
    },

    deleteInvite(orgId, inviteId) {
        const axiosOptions = {};
        return ApiService.delete(
            `${URLS.deleteInvite}`
                .replace(":orgId", orgId)
                .replace(":inviteId", inviteId),
            axiosOptions,
        );
    },

    deleteInviteForUser(inviteId) {
        const axiosOptions = {};
        return ApiService.delete(
            `${URLS.deleteInviteForUser}`.replace(":inviteId", inviteId),
            axiosOptions,
        );
    },

    sendInvite(orgId, data) {
        const axiosOptions = { data };
        return ApiService.post(
            `${URLS.sendInviteLink}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },

    orgListing(orgId) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.orgListing}`.replace(":orgId", orgId),
            axiosOptions,
        );
    },
    searchUsersByIds(orgId, ids) {
        const axiosOptions = {};
        return ApiService.get(
            `${URLS.searchUsersByIds}`
                .replace(":orgId", orgId)
                .replace(":ids", ids),
            axiosOptions,
        );
    },

    getOrganizationDetailsById(currOrgId, orgIds) {
        const data = orgIds
            ? Array.isArray(orgIds)
                ? orgIds
                : typeof orgIds === "string"
                  ? [orgIds]
                  : []
            : [];
        return ApiService.get(
            `${URLS.getOrganizationDetailsByIds.replace(":orgId", currOrgId)}${
                data?.length > 0
                    ? `?${data
                          .map(
                              (item, index) =>
                                  `${index === 0 ? "" : "&"}orgId=${item}`,
                          )
                          .join("")}`
                    : ""
            }`,
        );
    },
};

export default OrgService;
