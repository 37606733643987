import React, { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";

import Loader from "./Loader";

const PrivateRoute = ({ component: Component, guard, path, exact = false }) => {
    const [isLoaderVisible, setIsLoaderVisible] = useState(true);
    const [redirectTo, setRedirectTo] = useState("");

    useEffect(() => {
        const asyncGuard = async () => {
            const value = await guard();
            setRedirectTo(value);
            setIsLoaderVisible(false);
        };

        asyncGuard();
    }, []);

    return (
        <Route exact={exact} path={path}>
            {isLoaderVisible ? (
                <Loader show />
            ) : redirectTo.length > 0 ? (
                <Redirect to={redirectTo} />
            ) : (
                <Component />
            )}
        </Route>
    );
};

export default PrivateRoute;
